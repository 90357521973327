// font-size: 1.5rem;

$base-color: darken(#903749, 5);

.radio-toolbar input[type='radio']:checked + label {
  background: lighten($base-color, 12);
}

.radio-toolbar input[type='radio']:focus + label {
  background: lighten($base-color, 12);
  border: 2px solid #53354a;
}

.radio-toolbar label:hover {
  background: lighten($base-color, 5);
}

.radio-toolbar input[type='radio'] {
  opacity: 0;
  position: fixed;
  height: 0;
  width: 0;
}

.radio-toolbar {
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    margin-left: 30px;
  }
}

.radio-toolbar label {
  display: inline-block;
  cursor: pointer;
  background-color: $base-color;
  padding: 2.5rem 2.5rem;
  margin: 0.5rem;
  font-family: sans-serif, Arial;
  font-size: 16px;
  border: 2px solid #53354a;
  border-radius: 4px;
  left: 0;
  top: 0;
  position: relative;
  color: white;
  width: 90px;
  text-align: center;
  vertical-align: middle;
}
/* @media(min-width 440px){
    width 50%}
  
  @media(min-width 560px){
    width (100/3)%}
   */
