.bodyfat-box {
  margin-top: 80px;
}
.bodyfat-box-range {
  margin-left: 50px;
  margin-right: 50px;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    margin-left: 10px;
    margin-right: 10px;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.bodyfat-holder {
  display: flex;
}
.bodyfat-value {
  font-size: 39px;
  margin-left: 20px;
  line-height: 39px;
  font-weight: lighter;
}
.bodyfat-col {
  display: flex;
  flex-direction: column;
}
.fc-lp-dd {
  border-radius: 5px;
  padding: 10px;
  border: none;
  // margin-right: 40px;
  box-sizing: border-box;
  box-shadow: none;
  outline: none;
  border: none;
  width: 40%;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    border-radius: 5px;
    border: none;
    margin-right: 40px;
    box-sizing: border-box;
    box-shadow: none;
    outline: none;
    border: none;
    margin-top: 10px;
    justify-content: center;
    width: 100%;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    border-radius: 5px;
    border: none;
    margin-right: 40px;
    box-sizing: border-box;
    box-shadow: none;
    outline: none;
    border: none;
    margin-top: 10px;
    justify-content: center;
    width: 100%;
  }
}
select:focus {
  border: 2px solid #e84545;
  border-radius: 4px;
}

/* form groups */

.fc-form-group {
  display: flex;
  width: 100%;
  justify-content: center;
}

/* mid box */
.fc-lp-mid-box {
  display: flex;
  justify-content: space-between;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.fc-lp-mid-box > select {
  height: 37px;
}
// .fc-lp-mid-box-text {
//   text-align: center;
//   font-weight: bold;
//   margin-top: -10px;
//   margin-left: 20px;
// }

.fc-button-phase {
  opacity: 0.4;
}
// .info-icon {
//   cursor: pointer;
//   position: relative;
//   top: 18px;
//   color: white;
//   font-size: 1.05em;
//   border: 2px solid white;
//   width: 20px;
//   text-align: center;
//   text-shadow: 0px 0px 22px black;
//   margin-left: auto;
//   margin-right: auto;
//   border-radius: 100%;
//   -webkit-box-shadow: inset 0px 0px 22px 0px rgba(0, 0, 0, 0.75);
//   -moz-box-shadow: inset 0px 0px 22px 0px rgba(0, 0, 0, 0.75);
//   box-shadow: inset 0px 0px 22px 0px rgba(0, 0, 0, 0.75);
// }

.info-icon {
  position: relative;
  cursor: pointer;
  width: 20px;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid white;
  height: 10%;
  background-color: #ffffff;
  border-radius: 100%;
}

/* tool tip */
.tt-extra-space {
  margin-top: 5px !important;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    margin-top: 5px !important;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    margin-top: 5px !important;
  }
}
.tooltip {
  position: relative;
  display: inline-block;
  float: right;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    position: relative;
    display: inline-block;
    float: left;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    position: relative;
    display: inline-block;
    float: left;
  }
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 600px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  margin: 0 auto;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 105%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
