.chat-page-wrapper {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.chat-page-wrapper ul {
  margin: 0;
  padding: 0;
}
.chat-room-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.chat-room-list li {
  height: 100px;
  background: #3f4044;
  flex: 1 1 calc(50% - 4px);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.chat-room-list li a:link {
  text-decoration: none;
  color: white;
}
.messages-container {
  width: 50%;
  padding: 16px;
  flex-grow: 1;
  border: 1px solid #3f4044;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.message-input-container {
  display: flex;
  gap: 4px;
}

.message-input {
  padding: 12px 8px;
  flex: 1;
  background: #3f4044;
  border-radius: 5px;
  color: #fff;
}

.send-message {
  padding: 12px 14px;
  background: #0087ff;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}
.send-message-disabled {
  padding: 12px 14px;
  background: #0087ff;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  opacity: 0.5;
}

.message-list-container {
  margin-bottom: 16px;
  flex: 1;
  overflow: scroll;
}

.message-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style: none;
}

.message {
  padding: 8px 16px;
  margin-bottom: 8px;
  background: grey;
  border-radius: 5px;
  text-align: left;
}

.own-message {
  background: #0087ff;
  align-self: flex-end;
  text-align: right;
}

.sender {
  margin-bottom: 8px;
}
