.fc-chart-size {
  width: 240px;
  height: 240px;
}

.header-adjuster-left {
  text-align: left;
  margin-left: 0.625rem;
}
.fc-macro-flex-container {
  display: flex;
  flex-direction: row;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    display: flex;
    flex-direction: column;
  }
}
.fc-macro-container-firstItem {
  flex: 2;
}
.fc-macro-container-secondItem {
  flex: 1;
  justify-content: flex-end;
  display: flex;
}
.fc-macro-container-rightcol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fc-macro-footer-firstItem {
  padding: 3px;
  border-right: 1px solid grey;
  padding-right: 15px;
}
.test-class {
  margin-top: 100px;
  margin-bottom: -80px;
  justify-content: space-around;
}
.fix-lineheight {
  line-height: 55px;
}
.mp-box-kcal {
  font-size: 25px;
  font-weight: lighter;
}
.shorten-right-page {
  margin-top: -20px;
}
