.left-content {
  padding: 10px;
  margin-left: 80px;
  margin-right: 80px;
}
.right-content {
  text-align: center;
  margin-top: 90px;
}
.fc-logo-text {
  font-weight: bold;
  font-size: 4.5rem;
}
.fc-logo {
  height: 50%;
  width: 50%;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    width: 100%;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    width: 100%;
  }
}
.fc-lp-flex-row {
  display: flex;
}

.fc-one-rm-link > a {
  text-decoration: none;
}
.btn-lp {
  color: #ffffff;
  line-height: 35px;
  font-weight: bold;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    color: #ffffff;
    line-height: 10px;
    font-weight: bold;
    font-size: 0.75rem;
    padding: 15px;
    margin-top: -10px;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    color: #ffffff;
    line-height: 10px;
    font-weight: bold;
    font-size: 0.75rem;
    padding: 15px;
    margin-top: -10px;
  }
}

.lp-top-content {
  display: flex;
  justify-content: space-between;
}

.fc-one-rm-link {
  background-color: #e84545;
  margin-top: 3%;
  width: 110px;
  border-radius: 2px;
  height: 40px;
  text-align: center;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    display: flex;
    justify-content: center;
    background-color: #e84545;
    margin-left: 10px;
    width: 70px;
    border-radius: 2px;
    height: 40px;
    text-align: center;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    display: flex;
    justify-content: center;
    background-color: #e84545;
    border-radius: 2px;
    height: 40px;
    text-align: center;
  }
}
/* Landing page box css */
.fc-lp-first-block {
  display: flex;
  flex-direction: column;
  flex: 3;
}
.fc-lp-second-block {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.fc-land-page-digits {
  font-size: 27px;
  margin-top: 60px;
}
.fc-lp-right-icon {
  text-align: right;
  margin-bottom: 20px;
  margin-right: 20px;
}
.fc-lp-box-wrapper {
  display: flex;
  justify-content: space-between;
}
.fc-lp-med-header {
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.83em;
}

.fc-lp-wrapper-bottom {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

/* tool tip */

.tooltip {
  position: relative;
  display: inline-block;
  // float: right;
  margin-top: 4px;
  margin-left: 20px;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    position: relative;
    display: inline-block;
    // float: right;
    margin-top: 2px;
    margin-left: 20px;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    position: relative;
    display: inline-block;
    // float: right;
    margin-top: 2px;
    margin-left: 20px;
  }
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  margin: 0 auto;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 105%;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    position: absolute;
    z-index: 1;
    width: 200px;
    top: 100%;
    left: 50%;
    margin-left: -60px;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    position: absolute;
    z-index: 1;
    width: 200px;
    top: 100%;
    left: 50%;
    margin-left: -60px;
  }
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.info-icon-lp {
  position: relative;
  cursor: pointer;
  width: 20px;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid white;
  background-color: #ffffff;
  border-radius: 100%;
}

// .info-icon {
//   cursor: pointer;
//   position: relative;
//   top: 18px;
//   color: white;
//   font-size: 1.05em;
//   border: 2px solid white;
//   width: 20px;
//   text-align: center;
//   text-shadow: 0px 0px 22px black;
//   margin-left: auto;
//   margin-right: auto;
//   border-radius: 100%;
//   -webkit-box-shadow: inset 0px 0px 22px 0px rgba(0, 0, 0, 0.75);
//   -moz-box-shadow: inset 0px 0px 22px 0px rgba(0, 0, 0, 0.75);
//   box-shadow: inset 0px 0px 22px 0px rgba(0, 0, 0, 0.75);
// }
