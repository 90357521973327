/* Color */
$fatCalc-blue: #2b2e4a;
$fatCalc-maroon: #903749;
$fatCalc-red: #e84545;
$fatCalc-purple: #53354a;

body {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
body::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.fatcalc-user-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: grey;
  vertical-align: middle;
  margin: 100px 400px;
  border-radius: 32px;
  background-color: $fatCalc-blue;
  padding-bottom: 40px;
  color: #fff;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    margin: 40px 0px 0px 0px;
    border-radius: 0px;
  }
  @media (min-width: 320px) and (max-width: 1120px) {
    margin: 40px 0px 0px 0px;
    border-radius: 0px;
  }
}
@mixin fontandline {
  font-size: 20px;
  line-height: 28px;
}

//Standard spacing
$spacing-standard: 64px;
