@import '../../index.scss';

.fatcalc-login-logo-wrapper {
  height: 100px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}

.fatcalc-user-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.g-icon-size {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 45px;
  top: 15%;

  @media (min-width: 850px) and (max-width: 1480px) {
    left: -10px;
    position: relative;
  }

  @media (min-width: 320px) and (max-width: 840px) {
    left: 0px;
    position: static;
  }
}
.g-position {
  margin-right: -10px;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    margin-right: 0px;
  }
  @media (min-width: 320px) and (max-width: 1350px) {
    margin-right: 0px;
  }

  @media only screen and (max-width: 1368px) {
    margin-left: 10px;
  }
}
.fb-icon-size {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 50px;
  top: 20%;
  @media (min-width: 850px) and (max-width: 1480px) {
    left: -10px;
    top: -1px;
    position: relative;
    margin-right: 5px;
  }

  @media (min-width: 320px) and (max-width: 840px) {
    left: 0px;
    position: static;
    margin-right: 5px;
  }
  @media only screen and (max-width: 414px) {
    margin-left: 5px;
    height: 55px;
  }
  @media only screen and (max-width: 1368px) {
    margin-left: 5px;
    width: 55px;
    margin-right: -20px;
  }
}

.firebase-provider-btn {
  display: flex;
  position: relative;
  padding: 8px 0;
  background: #fff;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-size: 14px;
  font-family: inherit;
  height: 20px;
  border: 1px solid #c2c8d0;
  border-radius: 3px;
  color: #2d333a;
  cursor: pointer;
  outline: 0;
  margin-bottom: 10px;
}

.c3dd47fde {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
  text-transform: uppercase;
  border: none;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  color: #fff;
  padding: 24px 0 0;
}
.c3dd47fde:after {
  content: '';
  border-bottom: 1px solid #c2c8d0;
  flex: 1 0 auto;
  height: 0.5em;
  margin: 0;
}
.c3dd47fde:before {
  content: '';
  border-bottom: 1px solid #c2c8d0;
  flex: 1 0 auto;
  height: 0.5em;
  margin: 0;
}

@media only screen and (max-width: 414px) {
  .firebase-provider-btn {
    font-size: 8px;
  }
}
