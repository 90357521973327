input[type='text'] {
  padding: 10px 10px;
  box-sizing: border-box;
  box-shadow: none;
  outline: none;
  /* background: orange; */
  border: none;
  width: 100%;
  height: 40px;
  border-radius: 5px;
}
input[type='number'] {
  padding: 10px 10px;
  box-sizing: border-box;
  box-shadow: none;
  outline: none;
  /* background: orange; */
  border: none;
  width: 100%;
  height: 40px;
  border-radius: 5px;
}
input:focus {
  border: 2px solid #e84545;
  border-radius: 4px;
}
.field-group {
  margin: 40px 0;
  position: relative;
  width: 100%;
}

/* initially label is below input */
label {
  display: inline-block;
  position: absolute;
  left: 10px;
  top: 7px;
  transition: all 150ms ease-in;
  color: #676767;
  margin: 0;
}

/* when the label is activated, it jumps up (using translateY) */
/* you could add animation here as well */
label.field-active {
  transform: translateY(-25px);
  /* font-size: 0.9em;
  color: #000;
  text-shadow: 1px 0 0 #fff, -1px 0 0 #fff, 2px 0 0 #fff, -2px 0 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff,
    0 2px 0 #fff, 0 -2px 0 #fff; */

  transition: 0.5s;
  color: #e84545;
  font-weight: bold;
}
.floating-label {
  -webkit-appearance: none !important;
}
