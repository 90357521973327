@import '../../index.scss';

.fatcalc-user-wrapper input[type='text'] {
  margin: 20px 0;
}

.fatcalc-user-wrapper > h2 {
  color: white;
}
.fatcalc-form-col {
  width: 50%;
}
