@import '../../../index.scss';

.front-template-container {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  // height: 100%;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }
  @media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 4) and (orientation: portrait) {
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }
}

.ft-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  background: $fatCalc-blue;
  color: white;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: $fatCalc-blue;
    color: white;
  }
  @media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 4) and (orientation: portrait) {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: $fatCalc-blue;
    color: white;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: $fatCalc-blue;
    color: white;
  }
}

.ft-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
  }
  @media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 4) and (orientation: portrait) {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
  }
}

.ft-col-6 {
  display: inline-block;
  flex: 0 0 50%;
  max-width: 50%;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    display: inline-block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 4) and (orientation: portrait) {
    display: inline-block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    display: inline-block;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.btn {
  flex: 1 1 auto;
  /* margin: 10px; */
  padding: 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  cursor: pointer;
  color: white;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.btn:hover {
  background-position: right center;
}

.btn-fatcalc {
  background-image: linear-gradient(to right, #903749 0%, #e84545 51%, #903749 100%);
}
