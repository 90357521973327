@import '../../index.scss';
table {
  margin-top: 0px;
}
.fc-oldSchool {
  margin-top: -60px;
  height: 18.55rem;
  width: 16.56rem;
}
.fc-oneRm-flex-row {
  display: flex;
  margin-top: 80px;
}
.fc-page-move-text {
  font-size: 20px;
  color: black;
}

.fc-arrow {
  line-height: 15px;
  width: 20px;
  height: 20px;
  margin-top: 3px;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    line-height: 15px;
    width: 20px;
    height: 20px;
    margin-top: 4px;
  }
  @media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 4) and (orientation: portrait) {
    line-height: 15px;
    width: 20px;
    height: 20px;
    margin-top: 4px;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    line-height: 15px;
    width: 20px;
    height: 20px;
    margin-top: 4px;
  }
}

.fc-arrow-left {
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fc-one-rm-wrapper-bottom {
  margin-top: 120px;
  display: flex;
  justify-content: center;
}
.right-content a {
  text-decoration: none;
}
/* Using for rows */
.lp-table {
  border-collapse: collapse;
  margin: 20px 0 0 0;
  width: 100%;
}

.lp-table th {
  padding: 5px;
  text-align: center;
  background-color: black;
}
.lp-table th:first-child {
  width: 20px;
}
.lp-table td:nth-child(2) {
  padding: 5px;
  width: 20px;
  text-align: center;
}
.lp-table th:nth-child(3) {
  width: 45px;
}
.lp-table th:nth-child(4) {
  width: 55px;
}

.lp-table tr:nth-child(even) {
  background-color: $fatCalc-blue;
  /* background-color: #4d4d4d; */
}

.lp-table td {
  padding: 5px;
  text-align: center;
}
.lp-table tr {
  padding: 5px;
  text-align: center;
}
