@import '../../../../index.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $fatCalc-maroon;
  // background: linear-gradient(
  //   // 90deg,
  //   rgba(97, 71, 137, 1) 0%,
  //   rgba(143, 118, 181, 1) 35%,
  //   rgba(255, 255, 255, 1) 100%
  // );
  // background: linear-gradient(
  //   0deg,
  //   rgba(255, 255, 255, 1) 0%,
  //   rgba(164, 144, 195, 1) 35%,
  //   rgba(43, 46, 74, 1) 100%
  // );
  // background: linear-gradient(
  //   // 90deg,
  //   rgba(255, 255, 255, 1) 0%,
  //   rgba(143, 118, 181, 1) 35%,
  //   rgba(97, 71, 137, 1) 100%
  // );
  // background: linear-gradient(
  //   rgba(143, 118, 181, 1) 0%,
  //   rgba(97, 71, 137, 1) 35%,
  //   rgba(19, 18, 18, 1) 100%
  // );
  color: #333;
  height: 100px;
  padding: 0 80px;
  //box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 5px 0px rgb(0 0 0 / 75%);
  z-index: 999;
  position: relative;
  padding-top: 10px;
}
.logo {
  width: 50%;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    width: 100%;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    width: 100%;
  }
  @media screen and (min-width: 1900px) {
    width: 40%;
  }
}

.header a {
  color: #fff;
  margin-right: 20px;
  text-decoration: none;
  margin-top: 20px;
}

.header a:hover {
  color: black;
}
.header ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    height: auto;
  }

  .header ul {
    margin: 20px 0;
    flex-direction: column;
    text-align: center;
  }

  .header a {
    margin-right: 0;
    margin-top: 0px;
  }

  .logo {
    margin: 20px 0;
  }
}

.btn:hover {
  opacity: 0.9;
}

.btn-secondary {
  font: inherit;
  color: #fff !important;
  border: 0;
  border-radius: 5px;
  padding: 5px 0px;
  cursor: pointer;
}

.btn-div {
  font: inherit;
  color: #fff !important;
  border: 0;
  cursor: pointer;
  margin-bottom: 20px;
}

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-icon svg {
  margin-right: 5px;
}

.nav-login-element {
  height: '30px';
  margin-left: '-30px';
  @media (max-width: 768px) {
    margin-left: '0px';
  }
}
nav {
  width: auto;
}
.header > .nav-login-element > a {
  margin: 0;
}
