.ckbox-label {
  left: 7px;
  top: -14px;
  line-height: 10px;
  color: white;
  margin: 0;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    left: 42px;
    top: -14px;
    line-height: 10px;
    color: white;
    margin: 0;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    left: 42px;
    top: -14px;
    line-height: 10px;
    color: white;
    margin: 0;
  }
}
.ckbox {
  height: 28px;
  width: 28px;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ccc;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  background-image: url('../../../assets/White_check.svg.png');
  background-position: center;
  background-size: auto 50%;
  background-repeat: no-repeat;
}

.ckbox:checked {
  background-color: #66bb6a;
  border-color: #66bb6a;
  border-radius: 50%;
  opacity: 1;
}
.ckbox:focus {
  border-radius: 50%;
  border: none;
}

.round img {
  height: 13px;
  width: 13px;
  margin-left: 12px;
  margin-top: 10px;
  position: absolute;
  cursor: pointer;
}
/* CHECKBOXGROUP */
.cbg-flex {
  display: flex;
  margin: 0 auto;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
  }
}
.cbg-space {
  margin-right: 5rem;
}
