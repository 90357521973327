@import '../../../../index.scss';

.box-component {
  /* display: flex;
  flex-direction: column; */
  display: inline-block;
  width: 75%;
  height: 100%;
  /* opacity: 20%; */
  background-color: $fatCalc-red;
  color: white;
  text-align: left;
  padding: 10px;
  margin: 30px 10px;
  border-radius: 5px;
}

.max-size {
  max-width: 750px;
  max-height: 200px;
  @media (min-width: 320px) and (max-width: 840px) {
    max-width: 750px;
    max-height: 800px;
  }
}
