$base-color: #d75a4a;

.error-icon {
  height: 20px;
  width: 20px;
  color: crimson;
  margin-right: 5px;
  margin-top: -30px;
}
.error-text {
  color: darken($base-color, 12);
  font-weight: bold;
  margin-top: -30px;
}
