.one-rm-box {
  display: flex;
  flex-direction: column;
}

.one-rm-dd {
  max-height: 40px;
  margin-top: 40px;
  margin-bottom: 20px;
  width: 75px;
  margin-left: -2px;
}
.one-rm-box > select {
  height: 37px;
}
