.fc-macro-headliner {
  width: 100%;
  height: 100%;
  background-color: black;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}
.fc-macro-box {
  display: flex;
  flex-direction: column;
}
.radio-button-group-wrapper {
  display: flex;
  margin-top: 1.75rem;
  justify-content: space-around;
}

.second {
  margin: 1.75rem 0;
  width: 75%;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    margin: 1.75rem 0;
    width: 92%;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    margin: 1.75rem 0;
    width: 92%;
  }
}

.third {
  margin: 1.75rem 0;
  width: 50%;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    margin: 1.75rem 0;
    width: 100%;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    margin: 1.75rem 0;
    width: 100%;
  }
}
.fc-macro-checkbox {
  display: flex;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
.fc-macro-flex-input {
  display: flex;
  flex-direction: column;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}
.fc-macro-flex-input-item-2 {
  flex: 3;
  padding: 0;
  margin-top: -40px;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    flex: 3;
    padding: 0;
    margin-top: -40px;
    // margin-right: 10px;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    flex: 3;
    padding: 0;
    margin-top: -40px;
    // margin-left: 80px;
  }
}

.fc-macro-box > select {
  height: 37px;
}

.shorten {
  width: 30%;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    width: 100%;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    width: 100%;
  }
}
.shorten65 {
  width: 65%;
}

.right-headliner {
  margin-right: 1.25rem;
  float: right;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    margin-right: 0.5rem;
    float: right;
    font-size: 1rem;
  }
  @media (min-width: 320px) and (max-width: 840px) {
    margin-right: 0.5rem;
    float: right;
    font-size: 1rem;
  }
}
