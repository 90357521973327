._loading_overlay_wrapper {
  border-radius: 10px;
}
._loading_overlay_overlay {
  border-radius: 10px;
}
button,
input[type='submit'],
input[type='reset'] {
  border: none;
}
input[type='submit'] {
  width: 100%;
  border-radius: 10px;
}
