@import '../../../../index.scss';

.popupContent {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin: 7px 0;
}

.popupIcon {
  margin-right: 7px;
  margin-top: -7px;
}

.popUpContainer {
  display: flex;
  width: 100%;
  background-color: #ffffff;
  color: #111111;
  padding: 62px $spacing-standard;
  @include fontandline;
  font-weight: 600;
  top: 0;
  position: absolute;
  z-index: 9999;
}

.popUpBtn {
  text-align: center;
  width: 86px;
  height: $spacing-standard;
  border-radius: 32px;
  background-color: $fatCalc-blue;
  color: white;
  margin-left: auto;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  line-height: $spacing-standard;
  margin-top: -14px;
  margin-right: 100px;
}

.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
